.video-js {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 6px;
}

.video-js .vjs-dock-text {
    background: linear-gradient(180deg, rgba(42, 82, 190, .2) 35%, transparent) !important;
    height: 5% !important;
}

.video-js .vjs-dock-title, .vjs-dock-description {
    font-family: "Montserrat";
    font-size: 16px;
}

.mtz-download-btn {
    background: rgba(42, 82, 190, 1) !important;
}

.mtz-download-btn .mtz-download-btn-dropdown .mtz-download-btn-dropdown-item {
    background: rgba(42, 82, 190, 1) !important;
}

.mtz-download-btn .mtz-download-btn-dropdown .mtz-download-btn-dropdown-item:hover {
    background: rgba(7, 39, 100, 1) !important;
}

.video-js .vjs-big-play-button {
    background-color: rgba(42, 82, 190, 1) !important;
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.video-js .vjs-control-bar {
    opacity: .6;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "";
    background-image: url('/play-white-2.png');
    background-repeat: no-repeat;
    background-size: 36px;
    background-position: 55% calc(50% - 0px);
    border: none !important;
    box-shadow: none !important;
}

.vjs-poster {
    border-radius: 6px;
    /*background-size: contain;*/
    background-size: 100% !important;
}
